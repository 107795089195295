import { useEffect } from 'react';
import { Button } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Icon } from '@iconify/react';
import ConfirmPopup from 'atoms/ConfirmPopup';
import DropdownSelectFilter from 'atoms/DropdownSelectFilter';
import { PREVIEW_INFORMATION_TABS_OFFSET } from 'components/PreviewTab/PreviewEditorSideMenu';
import SearchFilter from 'components/SearchFilter';
import { ParameterModes, setConditionalFilter, updateParameterMode } from 'store/miscellaneous/miscellaneousSlice';
import { updateSearch } from 'store/parameters/parametersListingSlice';
import ExecuteContext from 'common/model/ExecuteContext';
import { listParameterCategoriesAndGroups } from 'common/api/parameters';
import { listContextParameters } from 'common/api/parameters';
import { Icons } from 'utils/utils-icons';
import './ParametersTabFilter.scss';

interface ParametersTabsFilterProps {
  context: ExecuteContext;
  contextId: string | undefined;
  checkIfTransaction: boolean;
  clearAnswers: () => void;
}

const ParametersTabFilter = ({ context, contextId, checkIfTransaction, clearAnswers }: ParametersTabsFilterProps) => {
  const dispatch = useAppDispatch();

  const { search } = useAppSelector((state: RootState) => state.parametersListing);
  const { activeGroupIds } = useAppSelector((state: RootState) => state.transactionDetail);
  const { selectedProvisionFilter, conditional, parameterMode } = useAppSelector(
    (state: RootState) => state.miscellaneous,
  );

  /*
  list of the provision that you can select in the dropdown menu
  const provisionsList: DropdownProps[] =
    context === ExecuteContext.Policy ? policyProvisionDropdown : transactionProvisionDropdown;
  */

  const dropdownParameterMode = () => {
    return [
      { key: 'normal', value: ParameterModes.Normal, text: 'Normal' },
      { key: 'detailed', value: ParameterModes.Detailed, text: 'Detailed' },
    ];
  };

  /* Might be needed in the future 
  const updateSelectedProvision = (id: string | undefined) => {
    dispatch(setSelectedProvisionFilter({ provisionId: id }));
  };*/

  const { activeTab: activePreviewTab } = useAppSelector((state: RootState) => state.hiddenMenu);

  const checkIfDiscussionTab = activePreviewTab === PREVIEW_INFORMATION_TABS_OFFSET.DISCUSSION;

  useEffect(() => {
    dispatch(
      listContextParameters({
        context,
        contextId,
        provisionId: checkIfDiscussionTab ? null : selectedProvisionFilter,
        conditional: checkIfDiscussionTab ? true : conditional,
        mode: checkIfDiscussionTab ? ParameterModes.Detailed : parameterMode,
      }),
    );
  }, [selectedProvisionFilter, parameterMode, conditional, activeGroupIds]);

  const onGetData = (value?: string) => {
    // @ts-ignore
    return listParameterCategoriesAndGroups({ searchValue: value, first: 10 });
  };

  return (
    <div className="filter-section-part">
      <SearchFilter
        search={search}
        onUpdateSearch={updateSearch}
        onGetData={onGetData}
        className="m-r-sm"
      />

      <DropdownSelectFilter
        className="m-r-sm parameter-mode"
        value={parameterMode}
        data-test="filter-by-parameter-mode"
        placeholder="Filter by Mode"
        options={dropdownParameterMode()}
        selection={true}
        onChange={(key, value) => dispatch(updateParameterMode(value as string))}
      />

      {checkIfTransaction && (
        <button
          className="filter-conditional"
          onClick={() => dispatch(setConditionalFilter({ conditional: !conditional }))}
        ></button>
      )}
      {!checkIfTransaction && (
        <ConfirmPopup
          trigger={
            <Button className="btn red-bg height-2_429 position-relative">
              <Icon icon={Icons.Trash} />
              CLEAR ALL ANSWERS
            </Button>
          }
          content="Do you really want to clear all answers?"
          confirmButtonText="DELETE"
          onConfirm={clearAnswers}
          cancelButtonText="CANCEL"
        />
      )}
    </div>
  );
};

export default ParametersTabFilter;
