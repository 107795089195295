import { Editor } from '@tinymce/tinymce-react';
import { useEffect, useRef, useState } from 'react';
import { RootState } from 'store';
import { Editor as CoreEditor } from 'tinymce';
import { useAppDispatch, useAppSelector } from 'hooks';
import { computeAllIndexesForDocument } from 'components/Editor/Clause/ComputeIndex';
import { removeActiveClassFromTextNode } from 'components/Editor/PageEditor';
import 'components/Editor/PageEditor/EditorStyles.scss';
import 'components/Editor/PageEditor/PageEditor.scss';
import { getClauseIndexFrom } from 'components/Editor/editorGetClauseIndexFrom';
import { EditorEvents, setup } from 'store/editor/setup';
import { FormatterAnswerList } from 'store/formatters/formattersListingSlice';
import { updateSidebarTab } from 'store/hiddenMenu/hiddenMenuSlice';
import {
  clearActiveParameters,
  setActiveProvisionId,
  updateActivePreviewTabContents,
  updateDocType,
} from 'store/miscellaneous/miscellaneousSlice';
import { resetActiveNode, setClauseIndex } from 'store/nodes/nodesSlice';
import { DiscussionMode, resetTransactionChannels, setDiscussionMode } from 'store/transactions/transactionDetailSlice';
import ExecuteContext from 'common/model/ExecuteContext';
import { executeListOfConditions, executeListOfFormatters } from 'common/api/formatters';
import { DocumentTypeClone } from 'common/api/miscellaneous';
import { ContentNodeType, getNode } from 'common/api/nodes';
import { PolicyProps } from 'common/api/policies';
import { ContentClone } from 'common/api/provisions';
import { TransactionProps } from 'common/api/transactions';
import { parseContent } from 'utils/utils-string';
import { PREVIEW_INFORMATION_TABS_OFFSET } from '../PreviewEditorSideMenu';
import { ProvisionListElementT, getListOfProvisionsFromContent } from '../PreviewEditorSideMenu/Tabs/TableOfContent';
import { insertRemoveConditionOnClause } from './insertRemoveConditionOnClause';
import { NODE_BORDER_COLORS, toggleClauseNumberBorderColor, toggleColorToNode } from './toggleColors';

// Get the NodeId from a list of Node Elements
export const getNodeIds = (elements: NodeListOf<Element>) => {
  return Array.from(elements)
    .map(element => element.getAttribute('data-node-id'))
    .filter(element => element) as string[];
};

enum ConditionResult {
  TRUE = 'true',
  FALSE = 'false',
  UNDEFINED = 'undefined',
}

function stripInlineStyles(htmlString: string) {
  // Create a temporary DOM element to parse the HTML
  const tempElement = document.createElement('div');
  tempElement.innerHTML = htmlString;

  // Recursive function to remove inline styles from elements
  function removeInlineStyles(element: any) {
    // Check if the element has any attributes
    if (element.attributes.length > 0) {
      // Loop through the attributes
      for (let i = 0; i < element.attributes.length; i++) {
        const attribute = element.attributes[i];
        // Check if the attribute is a style attribute and remove it
        if (attribute.name.toLowerCase() === 'style') {
          element.removeAttribute('style');
        }
      }
    }
    // Check if the element is a <code> tag and replace it with <span>
    if (element.tagName.toLowerCase() === 'code') {
      const spanElement = document.createElement('span');
      spanElement.innerHTML = element.innerHTML;
      element.parentNode.replaceChild(spanElement, element);
    }

    // Recurse through the element's children
    for (let i = 0; i < element.children.length; i++) {
      removeInlineStyles(element.children[i]);
    }
  }

  // Call the recursive function on the temporary DOM element
  removeInlineStyles(tempElement);

  // Return the modified HTML string
  return tempElement.innerHTML;
}

export interface DocumentTab {
  isAmendment?: boolean;
  name: string;
  id?: string;
}
export const getDocumentTabs = (
  documentTypesList: DocumentTypeClone[],
  activeContext: PolicyProps | TransactionProps,
  contents: ContentClone[],
): DocumentTab[] => {
  const permittedTabs = documentTypesList?.filter((documentType: DocumentTypeClone) => {
    return activeContext.documentTypeIds.includes(documentType.id);
  });

  const documentTabs: DocumentTab[] = [];

  for (let i = contents.length - 1; i >= 0; i--) {
    const content = contents[i];

    const tab = permittedTabs.find(documentType => documentType.id === content.documentType?.id)!;

    documentTabs.push({
      id: tab ? tab.id : undefined,
      isAmendment: content.isAmendment,
      name: content.isAmendment ? 'Supplementary Agreement' : tab.name,
    });
  }

  return documentTabs;
};

const PreviewEditorMain = ({ context }: { context: ExecuteContext }): JSX.Element => {
  const dispatch = useAppDispatch();

  const editorRef = useRef<CoreEditor | null>(null);
  const [tabNumber, setTabNumber] = useState<number>(1);
  const [showColor, setShowColor] = useState<boolean>(false);

  const { activePolicy, activePolicyContents } = useAppSelector((state: RootState) => state.policyDetail);
  const { activeTransaction, activeTransactionContents } = useAppSelector(
    (state: RootState) => state.transactionDetail,
  );
  const { formattedAnswersList, conditionAnswersList } = useAppSelector((state: RootState) => state.formattersListing);
  let { documentTypesList, activeDocType, activePreviewTabContents } = useAppSelector(
    (state: RootState) => state.miscellaneous,
  );

  const activeContext = context === ExecuteContext.Policy ? activePolicy : activeTransaction;
  const contents: ContentClone[] = context === ExecuteContext.Policy ? activePolicyContents : activeTransactionContents;

  const contextContents = contents?.map(({ content, documentType, isAmendment }) => {
    return { content, documentTypeId: documentType?.id, isAmendment };
  });

  const documentTabs: DocumentTab[] = getDocumentTabs(documentTypesList, activeContext, contents);

  const [hasEditorLoaded, setHasEditorLoaded] = useState<boolean>(false);

  const initCallback = (): void => {
    setHasEditorLoaded(true);
  };

  const editorPlugins = [
    'advlist',
    'autolink',
    'lists',
    'link',
    'image',
    'charmap',
    'anchor',
    'searchreplace',
    'visualblocks',
    'code',
    'fullscreen',
    'insertdatetime',
    'media',
    'table',
    'preview',
    'help',
    'wordcount',
    // 'noneditable',
    'nonbreaking',
  ];

  /**
   * Handle clicks on Policy Page
   */
  const handleEditorClickEvents = (editor: CoreEditor): void => {
    // Add a new event on Editor
    editor.selection.editor.on(EditorEvents.Click, event => {
      // Get the ID from the current clicked element
      let nodeId = event.target.getAttribute('data-node-id');
      // Get the Type from the current clicked element
      let nodeType = event.target.getAttribute('data-node-type');

      // Check if clicked over Parameter, Clause or Text nodes
      if (nodeType === null) {
        // Try to get the content from the parent
        const checkParent = event.target.parentElement;
        // Get the ID from the current clicked parent's element
        if (checkParent !== null) {
          nodeId = checkParent.getAttribute('data-node-id');
          // Get the Type from the current clicked parent's element
          nodeType = checkParent.getAttribute('data-node-type');
        }
      }

      // Check if click on the Clause Index element
      if (nodeType?.startsWith(ContentNodeType.CLAUSE_INDEX)) {
        let parent = event.target;

        // Try to find the Clause element
        for (let i = 0; i < 3 && parent; i++) {
          nodeId = nodeId ? nodeId : parent.getAttribute('data-node-id');
          nodeType = nodeType ? nodeType : parent.getAttribute('data-node-type');

          parent = parent.parentElement;
        }

        dispatch(updateSidebarTab(PREVIEW_INFORMATION_TABS_OFFSET.CONTROL));
        // Get the Index of Clause by the ID
        const clauseIndex = getClauseIndexFrom(editor, nodeId);
        // Set the Index Numbering to be used on the sidebar (Orange Circle)
        dispatch(setClauseIndex(clauseIndex));
      }
      // Check if click on Parameter node, Text node or the Clause Index element
      if (
        nodeId &&
        ([ContentNodeType.PARAMETER, ContentNodeType.TEXT].includes(nodeType) ||
          nodeType?.startsWith(ContentNodeType.CLAUSE_INDEX))
      ) {
        // Fetch the Node from the API and set it on Redux
        if (nodeType === ContentNodeType.PARAMETER) {
          dispatch(updateSidebarTab(PREVIEW_INFORMATION_TABS_OFFSET.CONTROL));
        }
        if (nodeType === ContentNodeType.TEXT) {
          dispatch(updateSidebarTab(PREVIEW_INFORMATION_TABS_OFFSET.CONTROL));
        }
        let updateEditor = removeActiveClassFromTextNode(editor);
        const [textNode] = updateEditor.dom.select(`[data-node-id="${nodeId}"]`);
        // Add active text node style
        textNode.classList.add('active-text-node');
        const updateContent = updateEditor.getContent();

        dispatch(
          updateActivePreviewTabContents({
            content: updateContent,
            active: true,
          }),
        );
        dispatch(getNode(nodeId));
      }
    });
  };

  /**
   * Handler for each document tab. It updated the redux with the current document ID
   */
  const updateActiveDoc = (index: number, doc: DocumentTab): void => {
    setHasEditorLoaded(false);
    dispatch(
      updateActivePreviewTabContents({
        content: '',
        active: true,
      }),
    );

    // Set number for specific array tab position
    setTabNumber(index + 1);
    // Update redux with the ID to set the active document on the page
    dispatch(
      updateDocType({
        id: doc.id,
        isAmendment: doc.isAmendment,
      }),
    );

    dispatch(resetActiveNode());

    // Reset Channels and Discussion
    if (context === ExecuteContext.Transaction) {
      dispatch(resetTransactionChannels());
      dispatch(setDiscussionMode(DiscussionMode.List));
    }

    dispatch(clearActiveParameters());
    dispatch(updateSidebarTab(PREVIEW_INFORMATION_TABS_OFFSET.TABLE_OF_CONTENTS));
  };

  const { provisionsList } = useAppSelector((state: RootState) => state.provisionsListing);
  useEffect(() => {
    // Get the current Document
    const contextContent = contextContents?.find(
      ({ documentTypeId, isAmendment }) =>
        documentTypeId === activeDocType?.id && (isAmendment === activeDocType?.isAmendment || isAmendment == null),
    );

    // If exist
    if (contextContent) {
      // Get the content from the current document
      const content: string = contextContent.content;
      // Convert string into a HTML object
      const contentParsed: Document = parseContent(content);

      // Using the contentParsed object, select all nodes with tags PARAMETER, CLAUSE or TEXT attribute
      const allParameterNodes: NodeListOf<Element> = contentParsed.querySelectorAll('[data-node-type="parameter"]');
      const allClauseNodes: NodeListOf<Element> = contentParsed.querySelectorAll('[data-node-type="clause"]');
      const allTextNodes: NodeListOf<Element> = contentParsed.querySelectorAll('[data-node-type="text"]');

      // Get all Node Ids
      const clauseIds: string[] = getNodeIds(allClauseNodes);
      const parameterIds: string[] = getNodeIds(allParameterNodes);
      const textNodeIds: string[] = getNodeIds(allTextNodes);

      // Dispatch a request to API to get clause condition result
      dispatch(
        executeListOfConditions({
          context: context,
          contextId: activeContext.id,
          nodeIds: [...clauseIds, ...textNodeIds],
        }),
      );
      // Dispatch a request to API to get parameter result
      dispatch(
        executeListOfFormatters({
          context: context,
          contextId: activeContext.id,
          nodeIds: parameterIds,
        }),
      );
    } else {
      // Else: set the current text as an empty string
      dispatch(
        updateActivePreviewTabContents({
          content: '',
          active: false,
        }),
      );
    }

    const compList = getListOfProvisionsFromContent(contents, activeDocType, provisionsList);
    if (compList.length !== 0) {
      dispatch(
        setActiveProvisionId({
          provisionId: (compList[0] as ProvisionListElementT).id,
        }),
      );
    }
  }, [activeDocType, contents]);

  // Sets the border color for clause or text
  const setBorderColor = (node: HTMLElement, nodeType: ContentNodeType, color: NODE_BORDER_COLORS): void => {
    if (nodeType === ContentNodeType.CLAUSE) {
      toggleClauseNumberBorderColor(node, color);
    } else if (nodeType === ContentNodeType.TEXT) {
      if (color !== NODE_BORDER_COLORS.GREEN) {
        toggleColorToNode(node, color);
      }
    }
  };

  useEffect(() => {
    // Get the content from the current document
    const contextContent = contextContents?.find(
      ({ documentTypeId, isAmendment }) =>
        documentTypeId === activeDocType?.id && (isAmendment === activeDocType?.isAmendment || isAmendment == null),
    );
    // Check if exist
    if (contextContent) {
      // Get the content from the current document
      const content: string = contextContent.content;
      // Convert string into HTML object
      const contentParsed: Document = parseContent(content);

      // Get all Clause from contentParsed object
      const allClauseNodes: NodeListOf<Element> = contentParsed.querySelectorAll('[data-node-type="clause"]');

      // Set all attributes data-level to zero
      // TODO why? we need explanation here on why we do this.
      Array.from(allClauseNodes).forEach(element => {
        element.setAttribute('data-level', '0');
      });

      /**
       * Management of the parameter nodes:
       *  - if the formatter returns a result, display it instead of the content text
       *  - remove the parameter node icon for parameter node that are evaluated
       *
       * formattedAnswersList list all the nodes that can have a condition (type CLAUSE or TEXT}
       * - nodeId contains the node od
       * - result contains the text evaluated by the formatter.
       *     -> if '' (empty string): we do not change the parameter node
       *     -> else : we update the content of the node with the result provided by the formatter
       * Note: the same node can appears several times in the content. Therfore, we need to evaluate
       *       each node multiple times.
       */
      // Instantiate object to create count of occurrences for the nodes as nodes can be used multiple times.
      const parameterNodeIdOccurrence: { [key: string]: number } = {};

      for (let i = 0; i < formattedAnswersList.length; i++) {
        // Get the current loop index position
        const formattedAnswer: FormatterAnswerList = formattedAnswersList.at(i)!;
        // Check if it exists
        if (!formattedAnswer) continue;

        const nodeId: string = formattedAnswer.nodeId!;
        const result: string = formattedAnswer.result!;

        // If the hash map has the attribute
        if (parameterNodeIdOccurrence[nodeId]) {
          // Increased
          parameterNodeIdOccurrence[nodeId]++;
        } else {
          // First time it was adding
          parameterNodeIdOccurrence[nodeId] = 1;
        }

        // Accessing a node from contentParsed with index gotten from the nodeIdOccurrence map.
        const formatterNodeIndex: number = parameterNodeIdOccurrence[nodeId] - 1;
        const selectNode: string = `[data-node-id="${nodeId}"]`;
        const node: HTMLElement = contentParsed.querySelectorAll<HTMLElement>(selectNode)[formatterNodeIndex];

        // If the node was found and result exist (different then empty string)
        if (node && result) {
          node.innerHTML = result;
          // Remove 'parameter-wrapper' class name, so that the "#" round icon disappear thanks to css style
          node.classList.remove('parameter-wrapper');
          // Add 'formatted-parameter-wrapper' to use an adapted css style.
          node.classList.add('formatted-parameter-wrapper');
        }
      }

      /**
       * Management of the clause and text nodes:
       * -> Add a green border if condition is evaluated as true just for clauses
       * -> Add a red border if condition is evaluated as false
       * -> Add a gray border if condition is not evaluated
       * -> display or not the nodes with condition depending on the condition evaluation
       * -> add borders in showColor mode to the nodes
       *
       * conditionAnswersList list all the nodes that can have a condition (type CLAUSE or TEXT}
       * - nodeId contains the node od
       * - result contains the condition evaluation result.
       *     -> if null: there is no condition, or it is not evaluated, hence the node is displayed
       *     -> if true: the node will be displayed
       *     -> if false: the node will not be displayed
       * Note: the same node can appears several times in the content. Therfore, we need to evaluate
       *       each node multiple times.
       */
      // Instantiate object to create count of occurrences for the nodes as nodes can be used multiple times.
      const nodeIdOccurrence: { [key: string]: number } = {};
      for (const { nodeId, result } of conditionAnswersList) {
        // If the hash map has the attribute
        if (nodeIdOccurrence[nodeId]) {
          // Increment
          nodeIdOccurrence[nodeId]++;
        } else {
          // First time it was increased
          nodeIdOccurrence[nodeId] = 1;
        }

        // Accessing a node from contentParsed with index gotten from the nodeIdOccurrence map.
        const nodeIndex: number = nodeIdOccurrence[nodeId] - 1;
        const selectNode: string = `[data-node-id="${nodeId}"]`;
        const node: HTMLElement = contentParsed.querySelectorAll<HTMLElement>(selectNode)[nodeIndex];

        // If the node was found
        if (node) {
          const nodeType = node.getAttribute('data-node-type') as ContentNodeType;
          if (nodeType) {
            switch (result) {
              case ConditionResult.TRUE:
                setBorderColor(node, nodeType, NODE_BORDER_COLORS.GREEN);
                break;
              case ConditionResult.FALSE:
                setBorderColor(node, nodeType, NODE_BORDER_COLORS.RED);
                break;
              case ConditionResult.UNDEFINED:
                setBorderColor(node, nodeType, NODE_BORDER_COLORS.GRAY);
                break;
            }
          }

          // Start to compare the API Answer with the Clause Condition
          if (result === ConditionResult.TRUE) {
            if (nodeType === ContentNodeType.CLAUSE) {
              // Remove the Condition C Circle from the Clause on the Editor
              insertRemoveConditionOnClause(node, false);
            }
            // If show color is active, then show the border
            showColor && toggleColorToNode(node, NODE_BORDER_COLORS.GREEN);
          } else if (result === ConditionResult.FALSE) {
            if (!showColor && nodeType === ContentNodeType.TEXT) {
              let parentElement = node.parentElement;
              // Remove the text node
              node.remove();

              // Get to the paragraph node text node was part of
              while (parentElement && parentElement.nodeName !== 'P') {
                parentElement = parentElement.parentElement;
              }

              // Count number of children inside paragraph node
              const childElementCount = parentElement !== null ? parentElement.childNodes.length : 0;
              // Remove empty paragraph without any childs ex. <p></p>
              if (childElementCount === 0) {
                parentElement?.remove();
              }

              // Remove paragraph if no content was there other than the text node
              // Remove the empty paragraph with line break or empty span after removal of node
              if (childElementCount === 1) {
                // Check if paragraph is having empty html tags then if yes remove the paragraph
                // ex. <p><span></span></p> or <p><strong><span></span></strong></p>
                // Don't remove if some content is present inside paragraph ex. <p>abcd</p>
                if (parentElement?.childNodes[0].textContent === '') {
                  parentElement?.remove();
                }
              }
            }

            // REMOVE THE Clause or Text Node

            if (nodeType === ContentNodeType.CLAUSE) {
              !showColor && node.remove();
              // If false then remove the Condition C Circle from the Clause on the Editor
              insertRemoveConditionOnClause(node, false);
            }
            // If show color is active, then show the border
            showColor && toggleColorToNode(node, NODE_BORDER_COLORS.RED);
          } else if (result === ConditionResult.UNDEFINED) {
            if (nodeType === ContentNodeType.CLAUSE) {
              // If false then remove the Condition C Circle from the Clause on the Editor
              insertRemoveConditionOnClause(node, true);
            }
            // If show color is active, then show the border
            showColor && toggleColorToNode(node, NODE_BORDER_COLORS.BLUE);
          } else {
            // If show color is active, then show the border
            showColor && toggleColorToNode(node, NODE_BORDER_COLORS.YELLOW);
          }
        }
      }

      // Updated content with all modification done on the nodes
      const innerHTML = computeAllIndexesForDocument(contentParsed);
      // Update the Redux store with updated and contentParsed HTML
      dispatch(
        updateActivePreviewTabContents({
          content: innerHTML,
          active: false,
        }),
      );
    } else {
      // Else: set the current text as an empty string
      dispatch(
        updateActivePreviewTabContents({
          content: '',
          active: false,
        }),
      );
    }
  }, [formattedAnswersList, conditionAnswersList, showColor]);

  /**
   * Function for the hidden button. It toggles the show/hide colors
   */
  const handleShowColors = (): void => {
    setShowColor(show => !show);
  };

  if (!showColor) {
    activePreviewTabContents = activePreviewTabContents.replace(
      /<div data-node-type="section"[^>]*>[^~]*?<\/div>/g,
      '',
    );
  }

  return (
    <div className="page-body-container">
      <button onClick={handleShowColors}> </button>
      <div
        className="editor-tabs"
        data-test="document-tabs"
      >
        {documentTabs?.map((documentTab: DocumentTab, index: number) => (
          <div
            className={tabNumber === index + 1 ? 'is-active' : ''}
            onClick={() => {
              updateActiveDoc(index, documentTab);
            }}
            key={index}
          >
            {documentTab.name}
          </div>
        ))}
      </div>
      {documentTabs.map((documentTab: DocumentTab, index: number) => {
        if ((tabNumber === index + 1) === false) return null;

        return (
          <div
            key={documentTab.name}
            className="editor-body"
          >
            {!hasEditorLoaded && <h1>Loading editor...</h1>}
            <div
              className={hasEditorLoaded ? 'visible' : 'hidden'}
              data-test="provision-editor-container"
            >
              <Editor
                id={documentTab.name}
                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                onInit={(_e, editor) => {
                  editorRef.current = editor;
                  handleEditorClickEvents(editor);
                }}
                value={activePreviewTabContents}
                init={{
                  menubar: false,
                  toolbar: false,
                  plugins: editorPlugins,
                  height: '100%',
                  width: '100%',
                  table_toolbar: '',
                  table_resize_bars: false,
                  contextmenu: false,
                  contextmenu_never_use_native: true,
                  font_size_formats: '8pt 9pt 10pt 11pt 12pt 14pt 15pt 18pt 24pt 36pt',
                  content_css: '/tinymce-css/tinymce-style.css',
                  body_class: 'preview-tab-editor',
                  disable_nodechange: true,
                  extended_valid_elements:
                    context === ExecuteContext.Transaction ? 'span[data-iteration|*],svg[*]' : '',
                  setup: editor => setup(editor, initCallback, true),
                }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PreviewEditorMain;
