import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { BreadCrumbItem } from 'layouts/BreadCrumb';
import { PageHeaderProps } from 'layouts/PageHeader';
import PageLayout from 'layouts/PageLayout';
import { ViewProps } from 'common/api/miscellaneous';
import { listParameterGroups, listParameterSubGroups } from 'common/api/parameters';
import { getParameter } from 'common/api/parameters';
import ParametersTabs from './ParametersTabs';

interface CreatorViewerProps extends ViewProps {}

const ParameterCreatorViewer = ({ isViewPage }: CreatorViewerProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { activeParameter, paramViewSelectedGroup, isLoading } = useAppSelector(
    (state: RootState) => state.parameterDetail,
  );

  useEffect(() => {
    if (isViewPage) {
      // @ts-ignore
      dispatch(getParameter({ id }));
    }

    // @ts-ignore
    dispatch(listParameterGroups());
    // @ts-ignore
    dispatch(listParameterSubGroups());
  }, [id]);

  const getPageHeaderProps = (): PageHeaderProps => {
    const currentPageTitle: string | null = activeParameter.name;

    const breadCrumb: BreadCrumbItem[] = [
      {
        title: 'Parameters',
        url: '/parameters/dashboard',
      },
    ];

    if (paramViewSelectedGroup !== null) {
      breadCrumb.push({
        title: paramViewSelectedGroup.name,
        url: `/parameters/dashboard?group=${paramViewSelectedGroup.id}`,
      });
    }

    breadCrumb.push({
      title: currentPageTitle || '',
    });

    return {
      showBreadCrumb: true,
      breadCrumb,
    };
  };

  return (
    <PageLayout
      pageSpinner={isLoading}
      pageHeader={getPageHeaderProps()}
    >
      <ParametersTabs />
    </PageLayout>
  );
};

export default ParameterCreatorViewer;
